@import "../global.scss";

.layout{

    &__header{

        &-box{
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            z-index: 10;
            min-width: var(--screen-min-width);
        }

        width: 100%;
        box-sizing: unset;
        padding: 24px 120px;
        // border-bottom: 1px solid var(--Gray-true-200, #E5E5E5);
        background: rgba(255, 255, 255, 0.30);
        backdrop-filter: blur(40px);
        position: relative;
        
        &__logo{
            justify-self: flex-start;
            left: 0;
            position: absolute;
            padding: 24px 120px;
            color: var(--Base-Black, #000);
            font-family: Poppins;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }

    &__footer{
        min-width: var(--screen-min-width);
        padding: 72px 84px 60px 84px;
        background: var(--Base-Black, #000);
        backdrop-filter: blur(10px);
    }


}

.menu{

    &__item{
        cursor: pointer;
        min-width: vw(82);

        color: #464646;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        transition: font-weight .3s ease-in-out, color .3s ease-in-out;

        &_activate{
            color: var(--Base-Black, #000);
            font-weight: 600;
        }
        
        position: relative;
    }
}

.menu-list{

    &-box{
        position: absolute;
        left: 0;
        top: 36px;
    }

    width: vh(296);
    padding: var(--spacing-md, 8px);

    border-radius: 12px;
    border: 1px solid var(--Gray-200, #EAECF0);
    background: var(--Base-White, #FFF);
    /* Shadow/lg */
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    opacity: 0;
    
    &_activate{
        opacity: 1;
        
    }
    transition: opacity .3s;

    &__item{
        text-align: left;
        box-sizing: border-box;
        padding: var(--spacing-md) 16px;

        border-radius: unset;
        background: unset;

        color: var(--Base-Black, #000);
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */

        &_activate{
            border-radius: 8px;
            background: var(--Gray-50, #F9FAFB);
        }

        &:hover{
            border-radius: 8px;
            background: var(--Gray-50, #F9FAFB);
        }

        transition: background-color .3s, border-radius .3s;
    }


}

.footer{
    

    &__logo{

    }

    &__company{
        color: #BEBEBE;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 171.429% */
    }

    &__product{
        &__title{
            color: #FFF;
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

        &__items{
            color: #FFF;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: uppercase;
        }

        &__item__link{
            cursor: pointer;
        }
    }

    &__contactus{
        &__title{
            color: #FFF;
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

        &__items{
            color: #FFF;
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }

    &__address{
        &__title{
            color: #FFF;
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

        &__items{
            width: 247px;
            color: #FFF;
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 133.333% */
        }
    }
}