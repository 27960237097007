@import "./global.scss";

.mb{
    &-products{
        // height: vh(1764);
        background-image: url("../../assets/images/mobile_products_bg@3x.png");
        background-size: 100vw auto;
        background-repeat: no-repeat;
        &__title-screen{
            height: unset;
            padding: 64px 16px;
            padding-top: calc(64px + 72px);
            &_disappear{
                opacity: 0;
            }
            opacity: 1;
            transition: opacity .3s;
        }
    
        &__title{
            
            color: var(--Base-Black, #000);
            text-align: center;
            /* Display/Medium */
            font-family: Poppins;
            font-size: 36px;
            font-style: normal;
            font-weight: 600;
            line-height: 44px; /* 122.222% */
        }
    
        &__subTitle{
            color: var(--Base-Black, #000);
            text-align: center;

            /* Text lg/Regular */
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    
    
        &__welcome{

            &-screen{
                height: unset;
                padding: 64px 16px;
            }

            padding: var(--spacing-4xl, 32px) 16px;

            border-radius: 16px;
            border: 1px solid #DADADA;
            background: rgba(228, 228, 228, 0.20);
            backdrop-filter: blur(10px);
            
            &__title{
                color: var(--Base-Black, #000);
                /* Display xs/Semibold */
                font-family: Poppins;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
    
            &__subTitle{
                color: var(--Base-Black, #000);
                /* Text md/Regular */
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }

            &__btn{
                width: 100%;
            }
        }
    
    
    }
    
    &-product{
        flex: 1 1;
        &-box{
            margin: auto;
        }
    
        &__icon-box{
            width: 64px;
            height: 64px;
            padding: 10.737px;
    
            border-radius: 44.737px;
            background: #FFF;
            box-shadow: 0px 0px 5.368px 0px rgba(0, 0, 0, 0.08);
        }
    
        &__title{
            color: var(--Base-Black, #000);
            text-align: center;
            /* Text md/Regular */
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    
        &__description{
            color: var(--Base-Black, #000);
            text-align: center;

            /* Text sm/Regular */
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
}