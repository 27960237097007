
@import "../global.scss";


.notification{

    &-box{
        z-index: 10;
        position: fixed;
        right: vw(24);
        bottom: vh(24);

        transform: translateX(vw(640 + 32));
        transition: transform .3s;
        &_show{
            transform: translateX(0);
        }
    }

    width: vw(640);
    padding: 16px;
    border-radius: 12px;
    border: 1px solid var(--Success-300, #6CE9A6);
    background: var(--Success-25, #F6FEF9);
    
    &__text{
        flex: 1 1;
        text-align: left;
    }

    &__close{
        cursor: pointer;
    }

    &_success{
        align-self: stretch;

        color: var(--Success-700, #027A48);
        /* Text sm/Medium */
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    &_error{

        border: 1px solid #F00;
        background: #FFF6F6;

        align-self: stretch;
        color: #f00;
        /* Text sm/Medium */
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

}