@import "../global.scss";

.mb{
    &-rb{
        &-inline-block{
            display: inline-block;
        }
        &-button{
            padding: 14px 24px;
            border-radius: 40px;
            background: #000;
            color: #FFF;
            /* Text md/Medium */
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
    
            cursor: pointer;
    
            &:active{
                border-radius: 40px;
                background: #393939;
            }

            &_disabled{
                border-radius: 40px;
                background: #B3B3B3;
                &:active{
                    border-radius: 40px;
                    background: #B3B3B3;
                }
            }
        }
    }
}
