@import "../global.scss";

.rb{
    &-inline-block{
        display: inline-block;
    }
    &-button{
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 180px;
        box-sizing: border-box;
        padding: 14px 24px;
        border-radius: 40px;
        background: #000;

        color: #FFF;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        cursor: pointer;

        &:hover{
            border-radius: 40px;
            background: #393939;
        }

        &_disabled{
            border-radius: 40px;
            background: #B3B3B3;
            &:hover{
                border-radius: 40px;
                background: #B3B3B3;
            }
        }
    }
}