body {
  margin: 0;
  font-family: Poppins;
}

* {
  box-sizing: border-box;
}

.App {
  text-align: center;
}


a{
  margin: 0;
  padding: 0;
  text-decoration: none;
  color:#333;
  &:hover{
    text-decoration: none;
  }
}

input,textarea{
  border:0;
  outline:0;
  }



:root{
  --home-box-with: 1240px;
  --spacing-xs: 4px;
  --spacing-md: 8px;
  --spacing-lg: 12px;
  --spacing-xl: 16px;
  --spacing-2xl: 16px;
  --spacing-3xl: 24px;
  --spacing-4xl: 32px;
  --spacing-6xl: 48px;

  --spacing-xs-n: 4;
  --spacing-md-n: 8;
  --spacing-lg-n: 12;
  --spacing-xl-n: 16;
  --spacing-2xl-n: 16;
  --spacing-3xl-n: 24;
  --spacing-4xl-n: 32;
  --spacing-6xl-n: 48;

  --screen-ratio: 1;
  --media-color:red

}

@media only screen and (width > 1920px) {
  :root{
      --scale-ratio: 1.33;
  }
  
}

@media only screen and (width <= 1920px) {
  :root{
      --scale-ratio: 1.33;
  }
}

@media only screen and (width <= 1680px) {
  :root{
      --scale-ratio: 1.16;
  }
  
}

@media only screen and (width <= 1440px) {
  :root{
      --scale-ratio: 1;
  }
  
}


@media only screen and (width <= 1280px) {
  :root{
      --scale-ratio: 0.88;
  }
}

