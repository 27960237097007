@import "./global.scss";


.mb{
    &-career{

        // height: 200vh;
        background-image: url("../../assets/images/mobile_careers_bg@3x.png");
        background-size: 100vw auto;
        // background-position: center 0;
        background-repeat: no-repeat;
        // background-position: center;
        
        box-sizing: border-box;
    
        &__title-box{
            height: auto;
            padding: 64px 16px;
            padding-top: calc(72px + 64px);
            padding-bottom: calc(269px + 64px);
        }
        
        &__title{
            text-align: center;
            /* Display/Medium */
            font-family: Poppins;
            font-size: 36px;
            font-style: normal;
            font-weight: 600;
            line-height: 44px; /* 122.222% */
    
            background: var(--blue-gradient, linear-gradient(90deg, #7948AA 14.25%, #49B7FF 86.08%));
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            
        }
    
        &__subTitle{
            color: var(--Base-Black, #000);
            text-align: center;
            
            /* Text lg/Regular */
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    
        
    }
    
    &-job-box{
    
        &-screen{
            height: unset;
            padding: 64px 16px;
        }
    
        padding: var(--spacing-4xl, 32px) 16px;

        border-radius: 16px;
        border: 1px solid #DADADA;
        background: rgba(228, 228, 228, 0.20);
        backdrop-filter: blur(10px);
    
        &__title{
            color: var(--Base-Black, #000);
            /* Display xs/Semibold */
            font-family: Poppins;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
    
        &__subTitle{
            color: var(--Base-Black, #000);
            /* Text md/Regular */
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
    
    &-job{
        &__title{
            box-sizing: border-box;
            padding-left: 17px;
            border-left: 1px #4AB6FE solid;
    
            color: var(--Base-Black, #000);
            /* Text md/Medium */
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    
        &__content{
            padding: 30px;
            border-top: 1.25px solid #4AB6FE;
            background: #FFF;
            &__icon{
                text-align: left;
            }
    
            &__description{
                text-align: left;
                color: var(--Base-Black, #000);
                /* Text sm/Regular */
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
    
            }
        }
    }
}