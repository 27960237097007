
@import "./global.scss";


.home{
    // height: 200vh;
    // background-image: url("../assets/images/homepage_bg.svg");
    // background-size: 100% vh(2245);
    background-image: var(--home-background-image);
    background-size: cover;
    background-position: center 0;
    background-repeat: no-repeat;
    // background-position: center 0;
    // background-repeat: no-repeat;
    box-sizing: border-box;
    min-width: var(--screen-min-width);
}

.title{
    &-screen{
        height: 100vh;
        box-sizing: border-box;
        // padding: var(--spacing-3xl, 24px) 0;
        padding-top: vh(248);
    }
    &-box{
        // height: 100%;
        margin: auto;
        // padding-top: 224px;
    }

    &__h1{
        color: var(--Base-Black, #000);
        text-align: center;
        font-size: 70px;
        font-weight: 600;
        // font-family: Poppins;
        // padding-top: 224px;
        line-height: normal;
        font-style: normal;
        
        &_gradient{
            // padding-top: unset;
            background: linear-gradient(90deg, #7948AA 14.25%, #49B7FF 86.08%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    &__h2{
        // margin-top: var(--spacing-4xl, 32px);
        color: var(--Base-Black, #000);
        text-align: center;
        // font-family: Poppins;
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    &__contactus-btn{
        text-align: center;
        margin-top: 150px;
    }
}

.contactus{

    &-screen{
        height: unset;
        min-height: var(--screen-height);
        box-sizing: border-box;
        padding: vh(124) 0;
    }

    &-box{
        width: vw(1240);
        margin: auto;
        box-sizing: border-box;
        padding: vh(64) vw(var(--spacing-6xl-n));

        border-radius: 24px;
        border: 1px solid #DADADA;
        background: rgba(228, 228, 228, 0.20);
        backdrop-filter: blur(10px);

    }

    &__header{
        &__title{
            color: var(--Base-Black, #000);
            font-size: 60px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
    
        &__subTitle{
            color: var(--Base-Black, #000);
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }

    &__form{
        &__item{
            &__label{
                color: var(--Base-Black, #000);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                &_error{
                    color: #F00;
                }
            }
            &__content{

            }

            &__error{
                color: #F00;
                /* Text sm/Regular */
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }

        &__input{
            box-sizing: border-box;
            width: vw(274);
            height: vh(40);
            padding: vh(10) vw(10);

            border-radius: 8px;
            border-bottom: 2px solid #555;
            background: #FFF;

            color: var(--Base-Black, #000);
            font-family: Poppins;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            &:focus{
                border-radius: 8px;
                border: 2px solid #555;
                background: #F5F4F5;
            }

            &_error{
                border: 2px solid #F00;
                &:focus{
                    border-radius: 8px;
                    border: 2px solid #F00;
                    background: #F5F4F5;
                }
            }
        }
        &__textarea{
            box-sizing: border-box;
            width: vw(598);
            height: vh(160);
            padding: vh(10) vw(10);

            border-radius: 8px;
            border-bottom: 2px solid #555;
            background: #FFF;

            color: var(--Base-Black, #000);
            font-family: Poppins;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            &:focus{
                border-radius: 8px;
                border: 2px solid #555;
                background: #F5F4F5;
            }

            &_error{
                border: 2px solid #F00;
                &:focus{
                    border-radius: 8px;
                    border: 2px solid #F00;
                    background: #F5F4F5;
                }
            }
        }
    }
    
}