
@function vh($px){
    @return calc($px/900*100vh)
}

@function vw($px){
    @return calc($px * var(--scale-ratio) * 1px)
}

:root{
  --screen-min-width: 1280px;
}

@media only screen and (width > 1920px) {
    :root{
        --home-background-image: url("../../assets/images/homepage_bg_1920p.svg");
        --career-background-image: url("../../assets/images/career_bg_1920p.svg");
        --products-background-image: url("../../assets/images/products_bg_1920p.svg");
        --screen-height: 1080px;

    }
    
  }
  
  @media only screen and (width <= 1920px) {
    :root{
        --home-background-image: url("../../assets/images/homepage_bg_1920p.svg");
        --career-background-image: url("../../assets/images/career_bg_1920p.svg");
        --products-background-image: url("../../assets/images/products_bg_1920p.svg");
        --screen-height: 1080px;
    }
  }
  
  @media only screen and (width <= 1680px) {
    :root{
        --home-background-image: url("../../assets/images/homepage_bg_1680p.svg");
        --career-background-image: url("../../assets/images/career_bg_1680p.svg");
        --products-background-image: url("../../assets/images/products_bg_1680p.svg");
        --screen-height: 1050;
    }
    
  }
  
  @media only screen and (width <= 1440px) {
    :root{
        --home-background-image: url("../../assets/images/homepage_bg.svg");
        --career-background-image: url("../../assets/images/career_bg.svg");
        --products-background-image: url("../../assets/images/products_bg.svg");
        --screen-height: 900px;
    }
    
  }
  
  
  @media only screen and (width <= 1280px) {
    :root{
        --home-background-image: url("../../assets/images/homepage_bg_1280p.svg");
        --career-background-image: url("../../assets/images/career_bg_1280p.svg");
        --products-background-image: url("../../assets/images/products_bg_1280p.svg");
        --screen-height: 720px;
    }
  }