@import "./global.scss";

:root{
    --title-box-width: 1200px;
    --job-box-width: 1240px;
}

.career{
    min-width: var(--screen-min-width);
    background-image: var(--career-background-image);
    background-size: cover;
    background-position: center 0;
    background-repeat: no-repeat;
    box-sizing: border-box;

    &__title-box{
        // height: var(--screen-height);
        height: 100vh;
        // height: 900px;
        box-sizing: border-box;
        padding-top: vh(152);
    }
    
    &__title{
        margin: auto;
        width: var(--title-box-width);
        text-align: center;
        font-family: Poppins;
        font-size: 70px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        background: linear-gradient(90deg, #7948AA 14.25%, #49B7FF 86.08%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    &__subTitle{
        // margin-top: vh(var(--spacing-4xl-n, 32));
        color: var(--Base-Black, #000);
        text-align: center;
        font-family: Poppins;
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    
}

.job-box{


    &-screen{
        height: auto;
        box-sizing: border-box;
        padding: vh(124) 0;
    }

    margin: auto;
    width: vw(1240);
    box-sizing: border-box;
    padding: vh(64) vw(var(--spacing-6xl-n));
    border-radius: 24px;
    border: 1px solid #DADADA;
    background: rgba(228, 228, 228, 0.20);
    backdrop-filter: blur(10px);

    &__title{
        color: var(--Base-Black, #000);
        font-family: Poppins;
        font-size: 60px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    &__subTitle{
        color: var(--Base-Black, #000);
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

.job{
    width: vw(295);
    &__title{
        box-sizing: border-box;
        padding-left: 17px;
        height: vh(27);
        border-left: 1px #4AB6FE solid;

        color: var(--Base-Black, #000);
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 27px */
        letter-spacing: 0.36px;
    }

    &__content{
        min-height: vh(250);
        box-sizing: border-box;
        padding: vh(30) vw(30);
        border-top: 1.25px solid #4AB6FE;
        background: #FFF;
        &__icon{
            width: vw(37.267);
            height: vh(34.049);
            flex-shrink: 0;
        }

        &__description{
            
            color: var(--Base-Black, #000);
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%; /* 21px */
            text-transform: capitalize;

        }
    }


}