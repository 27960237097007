
@import "./global.scss";


.mb{
    
    &-home{
        background-image: url("../../assets/images/mobile_homepage_bg@3x.png");
        background-size: 100vw auto;
        background-repeat: no-repeat;
        box-sizing: border-box;
    }
    
    &-title{
        &-screen{
            box-sizing: border-box;
            padding: 64px 16px;
            padding-top: calc(72px + 64px);
            height: 100vh;
        }
        &-box{
            
        }
    
        &__h1{
            color: var(--Base-Black, #000);
            text-align: center;

            /* Display/Medium */
            font-family: Poppins;
            font-size: 36px;
            font-style: normal;
            font-weight: 600;
            line-height: 44px; /* 122.222% */
            
            &_gradient{
                background: linear-gradient(90deg, #7948AA 14.25%, #49B7FF 86.08%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;

                /* Display/Medium */
                font-family: Poppins;
                font-size: 36px;
                font-style: normal;
                font-weight: 600;
                line-height: 44px;
            }
        }
    
        &__h2{
            color: var(--Base-Black, #000);
            text-align: center;

            /* Text lg/Regular */
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    
        &__contactus-btn{
            min-width: 163px;
        }
    }
    
    &-contactus{
    
        &-screen{
            height: unset;
            box-sizing: border-box;
            padding: 64px 16px;
        }
    
        &-box{
            box-sizing: border-box;
            padding: var(--spacing-4xl, 32px) 16px;
            border-radius: 16px;
            border: 1px solid #DADADA;
            background: rgba(228, 228, 228, 0.20);
            backdrop-filter: blur(10px);
        }
    
        &__header{
            &__title{
                color: var(--Base-Black, #000);
                font-family: Poppins;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
        
            &__subTitle{
                color: var(--Base-Black, #000);
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
    
        &__form{
            &__submit{
                width: 100%;
            }
            &__item{
                &__label{
                    color: var(--Base-Black, #000);
                    font-family: Poppins;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    
                    &_error{
                        color: #F00;
                    }
                }
                &__content{
                    
                }

                &__error{
                    color: #F00;
                    /* Text sm/Regular */
                    font-family: Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }
    
            &__input{
                width: 100%;
                height: 40px;
                box-sizing: border-box;
                padding: 10px;
                border-radius: 8px;
                border-bottom: 2px solid #555;
                background: #FFF;
    
                &:focus{
                    border-radius: 8px;
                    border: 2px solid #555;
                    background: #F5F4F5;
                }

                &_error{
                    border: 2px solid #F00;
                    &:focus{
                        border-radius: 8px;
                        border: 2px solid #F00;
                        background: #F5F4F5;
                    }
                }
            }
            &__textarea{
                width: 100%;
                height: 160px;
                box-sizing: border-box;
                padding: 10px;
                border-radius: 8px;
                border-bottom: 2px solid #555;
                background: #FFF;
    
                &:focus{
                    border-radius: 8px;
                    border: 2px solid #555;
                    background: #F5F4F5;
                }


            }
        }
        
    }
}