@import "./global.scss";


.products{
    min-width: var(--screen-min-width);
    // height: vh(1764);
    background-image: var(--products-background-image);
    background-size: cover auto;
    background-position: center 0;
    background-repeat: no-repeat;
    &__title-screen{
        height: 100vh;
        // height: var(--screen-height);
        &_disappear{
            opacity: 0;
        }
        opacity: 1;
        transition: opacity .3s;
    }

    &__title{
        
        margin: auto;
        width: vw(1200);
        box-sizing: border-box;
        padding-top: vh(248);

        color: var(--Base-Black, #000);
        text-align: center;
        font-family: Poppins;
        font-size: 70px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    &__subTitle{

        margin: auto;
        width: vw(1200);

        color: var(--Base-Black, #000);
        text-align: center;
        font-family: Poppins;
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    &__welcome-box{
        padding: vh(124) 0;
    }

    &__welcome{
        margin: auto;
        width: vw(1240);
        padding: vh(64) vw(var(--spacing-6xl-n));

        border-radius: 24px;
        border: 1px solid #DADADA;
        background: rgba(228, 228, 228, 0.20);
        backdrop-filter: blur(10px);

        &__title{
            color: var(--Base-Black, #000);
            font-family: Poppins;
            font-size: 60px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        &__subTitle{
            color: var(--Base-Black, #000);
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }


}

.product{
    cursor: pointer;
    

    &-box{
        margin: auto;
    }

    width: 400px;
    flex-shrink: 0;
    

    &__icon-box{
        box-sizing: border-box;
        padding: 17.05px;
        width: 108px;
        height: 108px;

        border-radius: 71.053px;
        background: #FFF;
        box-shadow: 0px 0px 8.526px 0px rgba(0, 0, 0, 0.08);
    }

    &__title{
        color: var(--Base-Black, #000);
        text-align: center;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    &__description{
        width: 400px;
        color: var(--Base-Black, #000);
        text-align: center;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    &__highlight{

        &-box{
            position: relative;
            height: var(--spacing-3xl, 24px);
            
        }

        width: 8px;
        height: 8px;
        position: absolute;
        // fill: #494949;
        &_hover{
            background: url("../../assets/images/ellipse.svg");
            background-size: 8px 8px;
        }
    }
}