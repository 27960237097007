@import "../global.scss";

.mb{
    &-layout{

        &__header{
    
            &-box{
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                z-index: 10;
            }
    
            height: 72px;
            padding: 0px var(--spacing-lg, 12px) 0px var(--container-padding-mobile, 16px);
            background: rgba(255, 255, 255, 0.30);
            backdrop-filter: blur(40px);

            &_activate{
                background: rgba(255, 255, 255, 1);
            }
            
            &__logo{
                color: var(--Base-Black, #000);
                /* Display xs/Medium */
                font-family: Poppins;
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
        }
    
        &__footer{
            display: flex;
            padding: var(--spacing-6xl, 48px) 0px;
            background: #000;
        }
    
    
    }
    
    &-menu{
        background: var(--Colors-Background-bg-primary, #FFF);
        box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
        padding: var(--spacing-3xl, 24px) 0px;
        border-bottom: 1px solid var(--Colors-Border-border-secondary, #EAECF0);

        max-height: 0;
        opacity: 0;
        &_activate{
            max-height: 999px;
            opacity: 1;
        }
        &_disapear{
            display: none;
        }
        transition: opacity .3s, max-height .3s;

        &__item{

            cursor: pointer;
            display: flex;
            padding: var(--spacing-lg, 12px) var(--spacing-xl, 16px);
            
            overflow: hidden;
            color: var(--colors-text-text-primary-900, #101828);
            text-overflow: ellipsis;

            /* Text md/Semibold */
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;

            &_activate, &:active{
                // color: var(--Base-Black, #000);
                // font-weight: 600;
                border-radius: var(--radius-none, 0px);
                background: var(--Colors-Background-bg-primary_hover, #F9FAFB);
            }
            
            position: relative;
        }

    }
    
    &-menu-list{

        &__item{
            cursor: pointer;
            display: flex;
            padding: var(--spacing-lg, 12px) var(--spacing-xl, 16px);
            
            overflow: hidden;
            color: var(--colors-text-text-primary-900, #101828);
            text-overflow: ellipsis;

            overflow: hidden;
            color: var(--Base-Black, #000);
            text-overflow: ellipsis;

            /* Text md/Regular */
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            &_activate, &:active{
                border-radius: var(--radius-none, 0px);
                background: var(--Colors-Background-bg-primary_hover, #F9FAFB);
            }
            
            position: relative;
        }
        
        opacity: 0;
        max-height: 0;  
        &_activate{
            opacity: 1;
            max-height: 999px;
        }

        transition: opacity .3s, max-height .3s;
    
    }
    
    &-footer{
        
        padding: 0px 16px;
        
        &__logo{
            padding: 0px var(--container-padding-mobile, 16px);
            color: #FFF;
            /* Display xs/Medium */
            font-family: Poppins;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

        &__items{
            padding: 0px 16px;
        }
    
        &__company{

            &-box{
                padding: 0px var(--container-padding-mobile, 16px);
            }
            
            padding-top: var(--spacing-4xl, 32px);

            border-top: 1px solid var(--Gray-true-800, #292929);
            color: var(--Gray-true-400, #A3A3A3);
            /* Text md/Regular */
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
        }
    
        &__product{
            &__title{
                color: #FFF;
                font-family: Poppins;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
    
            &__items{
                color: #FFF;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-transform: uppercase;
            }

            &__item__link{
                cursor: pointer;
            }     
        }
    
        &__contactus{
            &__title{
                color: #FFF;
                font-family: Poppins;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
    
            &__items{
                color: #FFF;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                // text-transform: uppercase;
            }
        }
    
        &__address{
            &__title{
                color: #FFF;
                font-family: Poppins;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
    
            &__items{
                color: #FFF;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                // text-transform: uppercase;
            }
        }
    }
}